<div fxLayoutAlign="center center">
  <app-filters-chimp
    [fields]="gridFilters"
    [filters]="filters"
    (removeFilter)="removeFilter.emit($event)"
  ></app-filters-chimp>
  <button
    mat-button
    (click)="openFilters.emit()"
    *ngIf="gridFilters?.length > 0"
  >
    <mat-icon>filter_alt</mat-icon>
    <span> {{ "common.grid.open-filter" | translate }} </span>
  </button>
  <button
    mat-button
    (click)="refreshPage.emit()"
  >
    <mat-icon>refresh</mat-icon>
    <span> {{ "common.grid.refresh" | translate }} </span>
  </button>
  <button mat-button (click)="addItem.emit()" *ngIf="canAdd">
    <mat-icon>add</mat-icon>
    <span> {{ addText }} </span>
  </button>
  <button
    mat-button
    (click)="Tracking.emit()" *ngIf="canView"
  >
    <mat-icon>add_location</mat-icon>
    <span> {{ "common.grid.open-tracking" | translate }} </span>
  </button>
  <button
    mat-button
    (click)="downloadQRcodes.emit()" *ngIf="models"
  >
    <mat-icon>cloud_download</mat-icon>
    <span> {{ "common.qrcode.download" | translate }} </span>
  </button>
</div>
