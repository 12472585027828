import { HttpClient } from '@angular/common/http';
import { EnvironmentConfig } from '../../enviroment-config';
import { BaseService } from './base.service';
/**
 * Classe astratta di servizio per effettuare le chiamate al backend applicativo
 */
export class SendApiService extends BaseService {
  constructor(
    protected basePath: string,
    protected http: HttpClient,
    config: EnvironmentConfig
  ) {
    super(config);
  }
  generate(code: string) {
    const path = this.getPath(`${this.basePath}`);
    return this.http.post(path, { code }, { responseType: 'blob' });
  }

  send(assignedVehicle: number, start_date: Date) {
    const path = this.getPath(`${this.basePath}`);
    return this.http.post(path, { assignedVehicle, start_date });
  }

}



