export type Permission = {
  action: Actions;
  model: string;
  module: string;
};

export enum Actions {
  ADD = 'add',
  VIEW = 'view',
  CHANGE = 'change',
  DELETE = 'delete',
}




export enum Modules {
  DATA_COLLECTOR = 'data_collector',
  AUTH = 'portal_auth',
  MANAGEMENT = 'group_management',
  TELEMETRY = 'telemetries',
  MOBILE = 'mquadro_mobile_app',
  MODA = 'm2_legacy'
}

export enum Models {
  DRIVER='modadriverstbl',
  VEHICLEQRCODE='vehicleqrcode',
  DEVICE = 'device',
  DEVICE_COLLECTOR = 'devicecollector',
  PORTAL_GROUP = 'customergroup',
  USER_PORTAL_GROUP = 'userportalgroup',
  VEHICLE = 'vehicle',
  VEHICLESVIEW = 'vehiclesview',
  TELEMETRY = 'telemetry',
  PORTAL_USER = 'appuser',
  ORDINIS = 'serviceorder',
  GPSTRACK= 'gpstrackpoint',
  GPSTRACKVIEW= 'gpstrackpointview',
  DEVICEEVENT = 'telemetriesdeviceeventview',
  CHASSIS= 'telemetrieschassisstate'
}
