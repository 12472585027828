import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { FilterField } from '../../dialogs/grid-filters/grid-filters.component';

@Component({
  selector: 'app-filters-chimp',
  templateUrl: './filters-chimp.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./filters-chimp.component.scss'],
})
export class FiltersChimpComponent {
  @Input() filters: object;
  @Input() fields: FilterField[];

  @Output() removeFilter = new EventEmitter<string>();

  getField(key: string) {
    return this.fields.find((i) => i.name === key);
  }
}
