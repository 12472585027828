import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Models } from 'src/app/data/model/permission';
import { FilterField } from '../../dialogs/grid-filters/grid-filters.component';

@Component({
  selector: 'app-grid-menu',
  templateUrl: './grid-menu.component.html',
  styleUrls: ['./grid-menu.component.scss'],
})
export class GridMenuComponent {
  @Input() addText: string;
  @Input() gridFilters: FilterField[];
  @Input() filters: object;
  @Input() canAdd: boolean;
  @Input() canView: boolean;
  @Input() models: Models.VEHICLEQRCODE;
  

  @Output() openFilters = new EventEmitter();
  @Output() removeFilter = new EventEmitter<string>();
  @Output() addItem = new EventEmitter();
  @Output() Tracking = new EventEmitter();
  @Output() sendOrders = new EventEmitter();
  @Output() refreshPage = new EventEmitter();
  @Output() downloadQRcodes = new EventEmitter();
}
