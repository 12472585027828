import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, tap } from 'rxjs';
import { Models, Modules, Permission } from 'src/app/data/model/permission';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuComponent implements OnInit, OnChanges {
  public menu: Map<string, Menu[]> = new Map();

  currentMenu: Menu[];

  @Input() viewPermissions: Permission[];

  constructor(private translate: TranslateService) {
    this.menu.set('it', it);
  }
  ngOnChanges(): void {
    this.currentMenu = this.authorizedMenu(
      this.menu.get(this.translate.currentLang)
    );
  }

  ngOnInit(): void {
    this.currentMenu = this.authorizedMenu(
      this.menu.get(this.translate.currentLang)
    );

    this.translate.onLangChange.pipe(
      map((langEvent) => this.menu.get(langEvent.lang)),
      map((menu) => this.authorizedMenu(menu)),
      tap((menu) => (this.currentMenu = menu))
    );
  }

  authorizedMenu(menu: Menu[]): Menu[] {
    return menu
      .map((menu) => this.menuWithoutUnauthorizedChild(menu))
      .filter((menu) => menu.items.length > 0);
  }

  menuWithoutUnauthorizedChild(menu: Menu): Menu {
    return {
      ...menu,
      items: menu.items.filter((child) => this.filterChild(child)),
    };
  }

  filterChild(child: MenuChild): boolean {
    return (
      !child.authorizationModel ||
      this.viewPermissions?.some(
        (pemrission) =>
          pemrission.model === child.authorizationModel.model &&
          pemrission.module === child.authorizationModel.module
      )
    );
  }
}

export type Menu = {
  label: string;
  items: MenuChild[];
};

export type MenuChild = {
  label: string;
  link: string[];
  authorizationModel?: {
    model: Models;
    module: Modules;
  };
};

const it: Menu[] = [
  /*{
    label: 'Report',
    items: [
      {
        label: 'Report Conducenti',
        link:  ['/drivers-job-report'],
        authorizationModel: {
          module: Modules.MODA,
          model: Models.DRIVER,
        },
      }
    ]
  },*/
  {
    label: 'Codici QR',
    items: [
      {
        label: 'Codici QR',
        link: ['/qr-codes'],
        authorizationModel: {
          module: Modules.MOBILE,
          model: Models.VEHICLEQRCODE,
        },
      },

    ],
  },
  {
    label: 'Gestione Automezzi',
    items: [
      {
        label: 'Ordini di servizio',
        link: ['/service-order'],
        authorizationModel: {
          module: Modules.DATA_COLLECTOR,
          model: Models.ORDINIS,
        },
      },
      {
        label: 'Veicoli',
        link: ['/vehicle'],
        authorizationModel: {
          module: Modules.DATA_COLLECTOR,
          model: Models.VEHICLE,
        },
      },
      {
        label: 'Device collector',
        link: ['/device-collector'],
        authorizationModel: {
          module: Modules.DATA_COLLECTOR,
          model: Models.DEVICE_COLLECTOR,
        },
      },
      {
        label: 'Dispositivi',
        link: ['/device'],
        authorizationModel: {
          module: Modules.DATA_COLLECTOR,
          model: Models.DEVICE,
        },
      },
      {
        label: 'Mappa',
        link: ['/map'],
        authorizationModel: {
          module: Modules.TELEMETRY,
          model: Models.GPSTRACK
        }
      },
    ],
  },
  {
    label: 'Telemetrie',
    items: [
      {
        label: 'Elenco telemetrie',
        link: ['/telemetries'],
        authorizationModel: {
          module: Modules.TELEMETRY,
          model: Models.TELEMETRY,
        },
      },
      {
        label: 'Anomalie',
        link: ['/device-event'],
        authorizationModel: {
          module: Modules.TELEMETRY,
          model: Models.DEVICEEVENT,
        },
      },
      {
        label: 'Telaio',
        link: ['/chassis-state'],
        authorizationModel: {
          module: Modules.TELEMETRY,
          model: Models.CHASSIS,
        },
      },
    ],
  },
  {
    label: 'Anagrafica',
    items: [
      {
        label: 'Utenti',
        link: ['/user'],
        authorizationModel: {
          module: Modules.AUTH,
          model: Models.PORTAL_USER,
        },
      },
      {
        label: 'Gruppi',
        link: ['/group'],
        authorizationModel: {
          model: Models.PORTAL_GROUP,
          module: Modules.MANAGEMENT,
        },
      },
    ],
  },
];
