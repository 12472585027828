<mat-toolbar
  color="primary"
  fxLayout.lt-md="row"
  fxLayoutAlign.lt-md="space-between center"
>
  <button fxShow="true" fxHide.gt-sm="true" mat-icon-button (click)="toggleMenu()">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="logo" routerLink="/">MQUADRO</span>
  <span class="spacer" fxHide.lt-md fxShow.gt-md></span>
  <app-navigation-menu fxHide.lt-md
    *ngIf="user$ | async"
    [viewPermissions]="viewPermission$ | async"
  ></app-navigation-menu>
  <!-- <app-select-language></app-select-language> -->
  <app-user-menu></app-user-menu>
</mat-toolbar>
