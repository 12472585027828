import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAnonymousGuard } from './core/guard/is-anonymous.guard';
import { IsAuthenticatedGuard } from './core/guard/is-authenticated.guard';

const routes: Routes = [
  {
    path: 'device-event',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/device-event/device-event.module')
        .then((m) => m.DeviceEventModule)
        .catch((err) => console.error('Device Event module Error', err)),
  },
  {
    path: 'service-order',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/service-order/service-order.module')
        .then((m) => m.ServiceOrderModule)
        .catch((err) => console.error('Service Order module Error', err)),
  },
  {
    path: 'chassis-state',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/chassis-state/chassis-state.module')
        .then((m) => m.ChassisStateModule)
        .catch((err) => console.error('Service Order module Error', err)),
  },
  {
    path: 'telemetries',
    canActivate: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/telemetry/telemetry.module')
        .then((m) => m.TelemetryModule)
        .catch((err) => console.error('Telemetry module Error', err)),
  },
  {
    redirectTo: 'login',
    pathMatch: 'full',
    path: '',
  },
  {
    path: 'qr-codes',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/vehicle-qr-code/vehicle-qr-code.module')
        .then((m) => m.VehicleQRcodeModule)
        .catch((err) => console.error('Vehicle QR code module Error', err)),
  },
  {
    path: 'drivers-job-report',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features//drivers-job-report/drivers-job-report.module')
        .then((m) => m.DriversJobReportModule)
        .catch((err) => console.error('Drivers Job Report module code module Error', err)),
  },
  {
    path: 'user',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/user/user.module')
        .then((m) => m.UserModule)
        .catch((err) => console.error('User module Error', err)),
  },
  {
    path: 'group',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/portal-group/portal-group.module')
        .then((m) => m.PortalGroupModule)
        .catch((err) => console.error('Portal group module Error', err)),
  },
  {
    path: 'device',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/device/device.module')
        .then((m) => m.DeviceModule)
        .catch((err) => console.error('Device module Error', err)),
  },
  {
    path: 'vehicle',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/vehicle/vehicle.module')
        .then((m) => m.VehicleModule)
        .catch((err) => console.error('Vehicle module Error', err)),
  },
  {
    path: 'device-collector',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/device-collector/device-collector.module')
        .then((m) => m.DeviceCollectorModule)
        .catch((err) => console.error('Device Collector module Error', err)),
  },
  {
    path: 'login',
    canLoad: [IsAnonymousGuard],
    loadChildren: () =>
      import('./features/login/login.module')
        .then((m) => m.LoginModule)
        .catch((err) => console.error('Login module Error', err)),
  },
  {
    path: 'map',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/map/map.module')
        .then((m) => m.MapModule)
        .catch((err) => console.error('Map module Error', err)),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
