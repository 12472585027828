<div style="margin: 0 10px" *ngIf="filters">
  <mat-chip-list>
    <mat-chip
      [removable]="true"
      (removed)="removeFilter.emit(filter.key)"
      style="margin: 5px 5px"
      *ngFor="let filter of filters | keyvalue"
    >
      <app-filter-chimp-text
        [key]="filter.key"
        [value]="filter.value"
        [field]="getField(filter.key)"
      ></app-filter-chimp-text>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</div>
