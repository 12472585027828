import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';
import { BaseComponent } from '../../base/base-component.directive';
import {
  FieldType,
  FilterField,
  FilterFieldOption,
} from '../../dialogs/grid-filters/grid-filters.component';

@Component({
  selector: 'app-filter-chimp-text',
  templateUrl: './filter-chimp-text.component.html',
  styleUrls: ['./filter-chimp-text.component.scss'],
})
export class FilterChimpTextComponent extends BaseComponent implements OnInit {
  @Input() key: string;
  @Input() value: unknown;
  @Input() field: FilterField;

  fieldValue: string;

  constructor(private changeDetector: ChangeDetectorRef) {
    super();
  }

  get fieldText() {
    return this.field?.text;
  }

  ngOnInit(): void {
    switch (this.field?.type) {
      case FieldType.AUTOCOMPLETE:
        this.field.options
          .pipe(
            map(
              (items: FilterFieldOption[]) =>
                items.find((item) => item.id === this.value)?.description
            )
          )
          .pipe(tap((item) => (this.fieldValue = item)))
          .pipe(tap(() => this.changeDetector.detectChanges()))
          .pipe(this.autoDispose())
          .subscribe();
        break;
      default:
        this.fieldValue = `${this.value}`;
    }
  }
}
